import React from "react";

const Map = () => {
	return (
		<div style={{ position: "relative" }}>
			<iframe
				title="Our Location"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3686.041118808527!2d88.34998201512401!3d22.502640385218914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02718d0bd1190f%3A0x3be19bc5a94812f8!2sMahindra%20First%20Choice%20(Universal%20Wheels)%20-%20Kolkata!5e0!3m2!1sen!2sin!4v1653355704072!5m2!1sen!2sin"
				width="100%"
				height="450"
				style={{ border: 0 }}
				allowFullScreen=""
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
			/>
		</div>
	);
};

export default Map;
