import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import productsData from "./assets/productsData";
import Contact from "./components/Contact";
import GlobalStyle from "./components/GlobalStyle";
import Map from "./components/Map";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Catalog from "./pages/Catalog";
import CatalogItem from "./pages/CatalogItem";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductContext from "./ProductContext";

const App = () => {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		// (async () => {
		// 	const retProducts = await axios(
		// 		"http://140.82.61.155:1337/api/products?populate=*"
		// 	);

		// 	setProducts(
		// 		retProducts.data.data.map((p) => {
		// 			console.log(p);
		// 			const product = {
		// 				id: p.id,
		// 				...p.attributes,
		// 			};

		// 			return {
		// 				id: product.id,
		// 				title: product.title,
		// 				thumbnail: `http://140.82.61.155:1337${product.thumbnail.data.attributes.url}`,
		// 				images: product.images.data.map(
		// 					(img) => `http://140.82.61.155:1337${img.attributes.url}`
		// 				),
		// 				category: product.category.data.attributes.name,
		// 				tags: product.tags.data.reduce(
		// 					(acc, curr) => acc + " " + curr + " ",
		// 					""
		// 				),
		// 				description: product.description,
		// 				type: product.type.data.attributes.name,
		// 				price: product.price,
		// 				rto: product.RTO,
		// 				make_year: product.make_year,
		// 				registeration_year: product.registeration_year,
		// 				transmission: product.transmission,
		// 				no_of_owner: product.no_of_owner,
		// 				km_driven: product.km_driven,
		// 				insurance_validity: product.insurance_validity,
		// 				insurance_type: product.insurance_type,
		// 			};
		// 		})
		// 	);
		// })();

		setProducts(productsData);
	}, []);

	return (
		<ProductContext.Provider value={{ products }}>
			<Router>
				<GlobalStyle />
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route path="/about" element={<About />}></Route>
					<Route path="/catalog" element={<Catalog />}></Route>
					<Route path="/catalog/:productId" element={<CatalogItem />}></Route>
					<Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
				</Routes>
				<Map />
				<Contact />
			</Router>
		</ProductContext.Provider>
	);
};

export default App;
