import React, { useContext, useState } from "react";
import styled from "styled-components";
import CheckBox from "../components/CheckBox";
import FilterDropdown from "../components/FilterDropdown";
import ItemCard from "../components/ItemCard";
import SkeletonCard from "../components/SkeletonCard";
import ProductContext from "../ProductContext";

const carType = [
	{
		id: 1,
		name: "Hatchback",
	},
	{
		id: 2,
		name: "Sedan",
	},
	{
		id: 3,
		name: "MUV",
	},
	{
		id: 4,
		name: "SUV",
	},
	{
		id: 5,
		name: "Premium",
	},
];

const price = [
	{
		id: 1,
		name: "Less than Rs. 2 Lakhs",
	},
	{
		id: 2,
		name: "2 Lakhs  – 3 Lakhs",
	},
	{
		id: 3,
		name: "3 Lakhs – 5 Lakhs",
	},
	{
		id: 4,
		name: "5 Lakhs – 8 Lakhs",
	},
	{
		id: 5,
		name: "8 Lakhs – 10 Lakhs",
	},
	{
		id: 6,
		name: "Greater than Rs. 10 Lakhs",
	},
];

const category = [
	{
		id: 1,
		name: "Petrol",
	},
	{
		id: 2,
		name: "Diesel",
	},
];

function Catalog() {
	const { products } = useContext(ProductContext);
	const [search, setSearch] = useState("");
	const [Checked, setChecked] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const filteredProducts = products.filter((product) => {
		const checkedSearchArr = Checked.map((item) => item.name.toLowerCase());
		const productTags = product.tags.split(" ");

		if (
			productTags.includes(search.toLowerCase()) ||
			(search.length >= 1 &&
				product.title.toLowerCase().includes(search.toLowerCase())) ||
			checkedSearchArr.includes(product.category.toLowerCase()) ||
			checkedSearchArr.includes(product.type.toLowerCase()) ||
			(checkedSearchArr.includes(price[0].name.toLowerCase()) &&
				product.price <= 200000) ||
			(checkedSearchArr.includes(price[1].name.toLowerCase()) &&
				product.price >= 200000 &&
				product.price <= 300000) ||
			(checkedSearchArr.includes(price[2].name.toLowerCase()) &&
				product.price >= 300000 &&
				product.price <= 500000) ||
			(checkedSearchArr.includes(price[3].name.toLowerCase()) &&
				product.price >= 500000 &&
				product.price <= 800000) ||
			(checkedSearchArr.includes(price[4].name.toLowerCase()) &&
				product.price >= 800000 &&
				product.price <= 1000000) ||
			(checkedSearchArr.includes(price[5].name.toLowerCase()) &&
				product.price >= 1000000)
		) {
			return product;
		}
		return false;
	});

	function loadMore() {
		// showLoading();
		setPage(page + 1);
	}
	function showLoading() {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 250);
	}
	return (
		<>
			{page === 1 && window.scrollTo({ top: 0, behavior: "smooth" })}
			<CatalogContainer>
				<FilterSidebar>
					<ProductSearchBar>
						<input
							placeholder="search"
							className="input"
							onChange={(e) => {
								showLoading();
								setSearch(e.target.value.toLowerCase());
							}}
						/>
					</ProductSearchBar>
					<FilterDropdown
						title="CATEGORY"
						content={
							<CheckBox
								checkboxData={category}
								Checked={Checked}
								setChecked={setChecked}
								showLoading={showLoading}
							/>
						}
					/>
					<FilterDropdown
						title="PRICE"
						content={
							<CheckBox
								checkboxData={price}
								Checked={Checked}
								setChecked={setChecked}
								showLoading={showLoading}
							/>
						}
					/>

					<FilterDropdown
						title="CAR TYPE"
						content={
							<CheckBox
								checkboxData={carType}
								Checked={Checked}
								setChecked={setChecked}
								showLoading={showLoading}
							/>
						}
					/>
				</FilterSidebar>
				<CardsContainer>
					<CardsHeader>
						<p>
							<b>
								<span style={{ color: "#9a9a9a" }}>
									{filteredProducts.length === 0
										? products.length
										: filteredProducts.length}
								</span>{" "}
								cars in Results
							</b>
						</p>
						<div>
							{/* <p>View</p>
              <i className="material-icons">view_agenda</i>
              <i className="material-icons">menu</i>
              <i className="material-icons">grid_view</i>
              <hr aria-orientation="vertical" /> */}
							{/* <p>Sort By:</p> */}
							{/* <NativeSelect
                disableUnderline
                defaultValue="Price"
                sx={{ p: "5px" }}
              >
                <option value="Price">Price</option>
                <option value="Availability">Availability</option>
              </NativeSelect> */}
						</div>
					</CardsHeader>
					{/* <hr /> */}
					<InnerContainer>
						{/* {filteredProducts.length === 0
              ? products.map((product) => (
                  <Link
                    to={`/catalog/${product.id}`}
                    style={{ textDecoration: "none", color: "#000" }}
                    key={product.id}
                  >
                    <ItemCard
                      productName={product.title}
                      price={product.price}
                      productImage={product.image}
                      year="2018"
                    />
                  </Link>
                ))
              : filteredProducts.map((product) => (
                  <Link
                    to={`/catalog/${product.id}`}
                    style={{ textDecoration: "none", color: "#000" }}
                    key={product.id}
                  >
                    <ItemCard
                      productName={product.title}
                      price={product.price}
                      productImage={product.image}
                      year="2018"
                    />
                  </Link>
                ))} */}

						{filteredProducts.length === 0
							? products.slice(0, page * 6).map((product) => {
									return loading ? (
										<SkeletonCard />
									) : (
										// <Link
										// 	to={`/catalog/${product.id}`}
										// 	style={{ textDecoration: "none", color: "#000" }}
										// 	key={product.id}
										// >
										<ItemCard
											productId={product.id}
											productName={product.title}
											price={product.price}
											productImage={product.thumbnail}
											year={product.year}
											key={product.id}
										/>
										// </Link>
									);
							  })
							: filteredProducts.slice(0, page * 6).map((product) => {
									return loading ? (
										<SkeletonCard />
									) : (
										// <Link
										// 	to={`/catalog/${product.id}`}
										// 	style={{ textDecoration: "none", color: "#000" }}
										// 	key={product.id}
										// >
										<ItemCard
											productId={product.id}
											productName={product.title}
											price={product.price}
											productImage={product.thumbnail}
											year={product.year}
											key={product.id}
										/>
										// </Link>
									);
							  })}
					</InnerContainer>

					{filteredProducts.length === 0
						? page * 6 < products.length && (
								<button onClick={loadMore}>Load more</button>
						  )
						: page * 6 < filteredProducts.length && (
								<button onClick={loadMore}>Load more</button>
						  )}
				</CardsContainer>
			</CatalogContainer>
			{/* <RelatedCars /> */}
		</>
	);
}

const CatalogContainer = styled.div`
	display: flex;
	margin-bottom: 150px;
	justify-content: space-between;
	padding-top: 30px;
	@media (max-width: 1100px) {
		flex-direction: column;
		justify-content: center;
	}
`;
const FilterSidebar = styled.div`
	background: #f7f7f7;
	position: relative;
	min-height: 100%;
	top: 70px;
	width: 20%;

	button {
		padding: 10px 30px;
		outline: none;
		border: none;
		font-family: "Poppins", sans-serif;
		cursor: pointer;
		background-color: #f8be3e;
		color: #000;
		float: right;
		margin-right: 30px;
		margin-top: 30px;
	}

	@media (max-width: 1100px) {
		display: flex;
		width: 100%;
		top: 70px;
		min-height: 0%;
	}
	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

const CardsContainer = styled.div`
	width: 77%;
	position: relative;
	top: 95px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-bottom: 80px;

	&::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}

	hr {
		width: 950px;
		background: #000;
		opacity: 0.4;
		outline: none;
		border: none;
		height: 1px;
		margin: 0 auto;
	}

	button {
		padding: 10px 30px;
		outline: none;
		border: none;
		font-family: "Poppins", sans-serif;
		cursor: pointer;
		background-color: #f8be3e;
		color: #000;
		left: calc(100vw - 85%);
		transition: 0.2s;
		margin: auto;
		display: flex;
		&:hover {
			filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
		}
	}

	@media (max-width: 1100px) {
		width: 100%;
	}
`;

const CardsHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 45px 5px;
	@media (max-width: 500px) {
		padding: 0 13px 5px;
	}
	hr {
		width: 1px;
		height: 25px;
		margin-bottom: 2px;
		background: #000;
		outline: none;
		border: none;
	}

	div {
		p {
			margin-right: 12px;
			margin-top: 7px;
		}

		display: flex;

		@media (max-width: 500px) {
			display: block;
			padding: 0 0px 5px;
		}
	}
`;

const InnerContainer = styled.div`
	margin: 40px;
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding: 20px;

	@media (max-width: 768px) {
		padding: 50px;
		max-width: 100%;
	}
	@media (max-width: 425px) {
		padding: 20px;
		margin: 25px;
	}
	@media (max-width: 375px) {
		margin: 20px;
	}
	@media (max-width: 330px) {
		margin: 5px;
	}
`;

const ProductSearchBar = styled.div`
	border-radius: 10px;
	text-align: center;
	margin: 20px 0 40px;

	.input {
		width: 300px;
		height: 20px;
		padding: 28px;
		padding-left: 20px;
		font-family: "Poppins", sans-serif;
		border: none;
		border-radius: 4px;
		outline: none;
		font-size: 17px;
	}

	@media (max-width: 1100px) {
		margin: 10px;
		.input {
			padding: 20px;
		}
	}
`;
export default Catalog;
