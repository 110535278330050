import React, { useState, useContext } from "react";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";
// import Card from "./Card";
import ItemCard from "./ItemCard";
import { Link } from "react-router-dom";

import ProductContext from "../ProductContext";

const Sidebar = ({ showSidebar, setShowSidebar }) => {
	const { products } = useContext(ProductContext);

	const [search, setSearch] = useState("");

	var filteredProducts = products.filter((product) => {
		const productTags = product.tags.split(" ");
		if (
			productTags.includes(search.toLowerCase()) ||
			product.title.toLowerCase().includes(search.toLowerCase())
		)
			return product;
		return false;
	});
	return (
		<SidebarComponent showSidebar={showSidebar}>
			<InputContainer>
				<FaSearch size={18} />
				<SearchInput
					placeholder="Search"
					onChange={(e) => {
						setSearch(e.target.value.toLowerCase());
					}}
				/>
			</InputContainer>

			<CardsContainer>
				{/* <Card manufacturing_year="2009" sidebar={true} />
        <Card manufacturing_year="2009" sidebar={true} />
        <Card manufacturing_year="2009" sidebar={true} />
        <Card manufacturing_year="2009" sidebar={true} /> */}

				{/* {productsdata.map((product) => (
          <Link
            to={`/catalog/${product.id}`}
            style={{ textDecoration: "none", color: "#000" }}
            key={product.id}
            onClick={() => setShowSidebar(false)}
          >
            <Card manufacturing_year="2009" sidebar={true} />
          </Link>
        ))} */}
				{filteredProducts.length === 0
					? products.map((product) => (
							<Link
								to={`/catalog/${product.id}`}
								style={{ textDecoration: "none", color: "#000" }}
								key={product.id}
							>
								<ItemCard
									productName={product.title}
									price={product.price}
									productImage={product.thumbnail}
									year={product.make_year}
								/>
							</Link>
					  ))
					: filteredProducts.map((product) => (
							<Link
								to={`/catalog/${product.id}`}
								style={{ textDecoration: "none", color: "#000" }}
								key={product.id}
							>
								<ItemCard
									productName={product.title}
									price={product.price}
									productImage={product.thumbnail}
									year={product.year}
								/>
							</Link>
					  ))}
			</CardsContainer>
		</SidebarComponent>
	);
};

const SidebarComponent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 0;
	width: ${({ showSidebar }) => (showSidebar ? "520px" : "0")};
	visibility: ${({ showSidebar }) => (showSidebar ? "visible" : "hidden")};
	position: fixed;
	z-index: 1;
	top: 0;
	right: 0;
	background-color: #000;
	overflow-x: hidden;
	transition: all 0.3s;
	@media (max-width: 1200px) {
		width: 450px;
	}
	// @media (max-width: 768px) {
	//   width: 60%;
	// }
	@media (max-width: 550px) {
		width: 100%;
	}
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 3.5rem;
	width: 18rem;
	margin: 55px 0 30px;
	border: 1px solid #fff;
	/* padding: 1rem; */
	/* background-color: orangered; */
`;

const SearchInput = styled.input`
	height: 100%;
	width: 70%;
	background-color: transparent;
	color: #fff;
	border: none;
	outline: none;
	font-size: 1.2rem;

	&::placeholder {
		color: #fff;
		font-size: 1.2rem;
	}
`;

const CardsContainer = styled.div`
	height: calc(100% - 3.5rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;

	a {
		margin: 20px 0;
		@media (max-width: 550px) {
			margin: 10px 0;
		}
	}
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: #333;
	}
`;

export default Sidebar;
