import React from "react";
import styled from "styled-components";
function Table({ data }) {
	const TableCell = ({ title, value }) => {
		return (
			<TableCellDiv>
				<p className="key">{title}</p>
				<p className="value">{value}</p>
			</TableCellDiv>
		);
	};
	return (
		<TableContainer>
			<p className="title">Car Overview</p>
			<TableInnerContainer>
				<table>
					<tr>
						<td>
							<TableCell title={"Make year"} value={data.make_year} />
						</td>
						<td>
							<TableCell
								title={"Registeration year"}
								value={data.registeration_year}
							/>
						</td>
						<td>
							<TableCell title={"Fuel Type"} value={data.type} />
						</td>
					</tr>
					<tr>
						<td>
							<TableCell title={"KM Driven"} value={data.km_driven} />
						</td>
						<td>
							<TableCell title={"Transmission"} value={data.transmission} />
						</td>
						<td>
							<TableCell title={"No. of Owner"} value={data.no_of_owner} />
						</td>
					</tr>
					<tr>
						<td>
							<TableCell
								title={"Insurance Validity"}
								value={data.insurance_validity}
							/>
						</td>
						<td>
							<TableCell title={"Insurance Type"} value={data.insurance_type} />
						</td>
						<td>
							<TableCell title={"RTO"} value={data.rto} />
						</td>
					</tr>
				</table>
			</TableInnerContainer>
		</TableContainer>
	);
}
export default Table;

const TableContainer = styled.div`
	color: #fff;
	display: block;
	width: 87%;
	.title {
		color: #f8be3e;
		font-size: 20px;
		font-weight: bolder;
		margin-bottom: 20px;
	}

	table {
		position: relative;
		z-index: 1;
		width: 100%;
		// border: 1px solid #6c6c6c;
		background-color: #1f1f1f;
		box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
		padding: 30px 50px;
		@media (max-width: 768px) {
			padding: 20px 20px;
		}
	}
	margin: 100px auto;
	td {
		padding: 20px;
	}
	tr {
		width: 100%;
	}
`;
const TableInnerContainer = styled.div`
	@media (max-width: 768px) {
		overflow: auto;
	}
`;
const TableCellDiv = styled.div`
	font-size: 16px;
	min-width: 150px;
	display: inline-block;
	.key {
		opacity: 0.5;

		font-size: 14px;
	}
	.value {
		opacity: 0.8;
	}
`;
