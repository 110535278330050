//
import Honda_City_VMT1 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT1.JPG";
import Honda_City_VMT10 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT10.JPG";
import Honda_City_VMT11 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT11.JPG";
import Honda_City_VMT12 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT12.JPG";
import Honda_City_VMT13 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT13.JPG";
import Honda_City_VMT14 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT14.JPG";
import Honda_City_VMT15 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT15.JPG";
import Honda_City_VMT2 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT2.JPG";
import Honda_City_VMT3 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT3.JPG";
import Honda_City_VMT4 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT4.JPG";
import Honda_City_VMT5 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT5.JPG";
import Honda_City_VMT6 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT6.JPG";
import Honda_City_VMT7 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT7.JPG";
import Honda_City_VMT8 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT8.JPG";
import Honda_City_VMT9 from "../assets/productImages/2013_Honda_City_1.5_VMT/2013_Honda_City_1.5_VMT9.JPG";
//
import Mahindra_KUV_100_K4_Plus1 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus1.jpeg";
import Mahindra_KUV_100_K4_Plus10 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus10.jpeg";
import Mahindra_KUV_100_K4_Plus11 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus11.jpeg";
import Mahindra_KUV_100_K4_Plus12 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus12.jpeg";
import Mahindra_KUV_100_K4_Plus13 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus13.jpeg";
import Mahindra_KUV_100_K4_Plus14 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus14.jpeg";
import Mahindra_KUV_100_K4_Plus15 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus15.jpeg";
import Mahindra_KUV_100_K4_Plus2 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus2.jpeg";
import Mahindra_KUV_100_K4_Plus3 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus3.jpeg";
import Mahindra_KUV_100_K4_Plus4 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus4.jpeg";
import Mahindra_KUV_100_K4_Plus5 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus5.jpeg";
import Mahindra_KUV_100_K4_Plus6 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus6.jpeg";
import Mahindra_KUV_100_K4_Plus7 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus7.jpeg";
import Mahindra_KUV_100_K4_Plus8 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus8.jpeg";
import Mahindra_KUV_100_K4_Plus9 from "../assets/productImages/Mahindra_KUV_100_K4_Plus/Mahindra_KUV_100_K4_Plus9.jpeg";
//
import Honda_Amaze_SMT1 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT1.jpeg";
import Honda_Amaze_SMT10 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT10.jpeg";
import Honda_Amaze_SMT11 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT11.jpeg";
import Honda_Amaze_SMT12 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT12.jpeg";
import Honda_Amaze_SMT13 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT13.jpeg";
import Honda_Amaze_SMT2 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT2.jpeg";
import Honda_Amaze_SMT3 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT3.jpeg";
import Honda_Amaze_SMT4 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT4.jpeg";
import Honda_Amaze_SMT5 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT5.jpeg";
import Honda_Amaze_SMT6 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT6.jpeg";
import Honda_Amaze_SMT7 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT7.jpeg";
import Honda_Amaze_SMT8 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT8.jpeg";
import Honda_Amaze_SMT9 from "../assets/productImages/Honda_Amaze_SMT/Honda_Amaze_SMT9.jpeg";
//
import creta1 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+1.jpg";
import creta10 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+10.jpg";
import creta11 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+11.jpg";
import creta12 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+12.jpg";
import creta2 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+2.jpg";
import creta3 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+3.jpg";
import creta4 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+4.jpg";
import creta5 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+5.jpg";
import creta6 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+6.jpg";
import creta7 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+7.jpg";
import creta8 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+8.jpg";
import creta9 from "../assets/productImages/2017-Hyundai-Creta-SX+/Hyundai_Creta_SX+9.jpg";
//
//
import mahindra_XUV1 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-1.jpeg";
import mahindra_XUV10 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-10.jpeg";
import mahindra_XUV11 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-11.jpeg";
import mahindra_XUV12 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-12.jpeg";
import mahindra_XUV13 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-13.jpeg";
import mahindra_XUV14 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-14.jpeg";
import mahindra_XUV2 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-2.jpeg";
import mahindra_XUV3 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-3.jpeg";
import mahindra_XUV4 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-4.jpeg";
import mahindra_XUV5 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-5.jpeg";
import mahindra_XUV6 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-6.jpeg";
import mahindra_XUV7 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-7.jpeg";
import mahindra_XUV8 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-8.jpeg";
import mahindra_XUV9 from "../assets/productImages/2020_Mahindra_XUV300_W6/Mahindra_XUV300_W6-9.jpeg";
//
import ciaz1 from "../assets/productImages/Ciaz/1.JPG";
import ciaz10 from "../assets/productImages/Ciaz/10.JPG";
import ciaz11 from "../assets/productImages/Ciaz/11.JPG";
import ciaz12 from "../assets/productImages/Ciaz/12.JPG";
import ciaz2 from "../assets/productImages/Ciaz/2.JPG";
import ciaz3 from "../assets/productImages/Ciaz/3.JPG";
import ciaz4 from "../assets/productImages/Ciaz/4.JPG";
import ciaz5 from "../assets/productImages/Ciaz/5.JPG";
import ciaz6 from "../assets/productImages/Ciaz/6.JPG";
import ciaz7 from "../assets/productImages/Ciaz/7.JPG";
import ciaz8 from "../assets/productImages/Ciaz/8.JPG";
import ciaz9 from "../assets/productImages/Ciaz/9.JPG";
//
//
import Maruti_Suzuki_WagonR_LXi1 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi1.JPG";
import Maruti_Suzuki_WagonR_LXi10 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi10.JPG";
import Maruti_Suzuki_WagonR_LXi11 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi11.JPG";
import Maruti_Suzuki_WagonR_LXi12 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi12.JPG";
import Maruti_Suzuki_WagonR_LXi2 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi2.JPG";
import Maruti_Suzuki_WagonR_LXi3 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi3.JPG";
import Maruti_Suzuki_WagonR_LXi4 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi4.JPG";
import Maruti_Suzuki_WagonR_LXi5 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi5.JPG";
import Maruti_Suzuki_WagonR_LXi6 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi6.JPG";
import Maruti_Suzuki_WagonR_LXi7 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi7.JPG";
import Maruti_Suzuki_WagonR_LXi8 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi8.JPG";
import Maruti_Suzuki_WagonR_LXi9 from "../assets/productImages/Maruti_Suzuki_WagonR_LXi/Maruti_Suzuki_WagonR_LXi9.JPG";
//
const productsData = [
	{
		id: 3,
		title: "Maruti Suzuki Ciaz VXi+",
		thumbnail: ciaz1,
		images: [
			ciaz1,
			ciaz2,
			ciaz3,
			ciaz4,
			ciaz5,
			ciaz6,
			ciaz7,
			ciaz8,
			ciaz9,
			ciaz10,
			ciaz11,
			ciaz12,
		],
		category: "petrol",
		price: 519000,
		tags: "ciaz marutiSuzuki grey",
		type: "Petrol",
		make_year: "October 2016",
		year: 2016,
		registeration_year: "November 2016",
		transmission: "Manual",
		no_of_owner: 1,
		km_driven: 48000,
	},

	{
		id: 13,
		title: "Mahindra XUV300 W6",
		thumbnail: mahindra_XUV1,
		images: [
			mahindra_XUV1,
			mahindra_XUV2,
			mahindra_XUV3,
			mahindra_XUV4,
			mahindra_XUV5,
			mahindra_XUV6,
			mahindra_XUV7,
			mahindra_XUV8,
			mahindra_XUV9,
			mahindra_XUV10,
			mahindra_XUV11,
			mahindra_XUV12,
			mahindra_XUV13,
			mahindra_XUV14,
		],
		category: "petrol",
		price: 829000,
		tags: "mahindra xuv green light",
		description: "",
		type: "Petrol",
		make_year: "Nov 2019",
		year: 2020,
		registeration_year: "Feb 2020",
		transmission: "Manual",
		no_of_owner: 1,
		km_driven: 58000,
	},

	{
		id: 14,
		title: "Hyundai Creta SX+",
		thumbnail: creta1,
		images: [
			creta1,
			creta2,
			creta3,
			creta4,
			creta5,
			creta6,
			creta7,
			creta8,
			creta9,
			creta10,
			creta11,
			creta12,
		],
		category: "petrol",
		price: 829000,
		tags: "hundai creta sx+ solid bold gray black",
		description: "",
		type: "Petrol",
		make_year: "Jan 2017",
		year: 2017,
		registeration_year: "Mar 2017",
		transmission: "Manual",
		no_of_owner: 2,
		km_driven: 48743,
	},

	{
		id: 16,
		title: "Honda City 1.5 VMT",
		thumbnail: Honda_City_VMT1,
		images: [
			Honda_City_VMT1,
			Honda_City_VMT2,
			Honda_City_VMT3,
			Honda_City_VMT4,
			Honda_City_VMT5,
			Honda_City_VMT6,
			Honda_City_VMT7,
			Honda_City_VMT8,
			Honda_City_VMT9,
			Honda_City_VMT10,
			Honda_City_VMT11,
			Honda_City_VMT12,
			Honda_City_VMT13,
			Honda_City_VMT14,
			Honda_City_VMT15,
		],
		category: "petrol",
		price: 349000,
		tags: "honda city dark red solid",
		description: "",
		type: "Petrol",
		make_year: "2013",
		year: 2013,
		registeration_year: "Jun 2013",
		transmission: "Manual",
		no_of_owner: 1,
		km_driven: 51000,
	},

	{
		id: 17,
		title: "Maruti Suzuki WagonR LXi",
		thumbnail: Maruti_Suzuki_WagonR_LXi1,
		images: [
			Maruti_Suzuki_WagonR_LXi1,
			Maruti_Suzuki_WagonR_LXi2,
			Maruti_Suzuki_WagonR_LXi3,
			Maruti_Suzuki_WagonR_LXi4,
			Maruti_Suzuki_WagonR_LXi5,
			Maruti_Suzuki_WagonR_LXi6,
			Maruti_Suzuki_WagonR_LXi7,
			Maruti_Suzuki_WagonR_LXi8,
			Maruti_Suzuki_WagonR_LXi9,
			Maruti_Suzuki_WagonR_LXi10,
			Maruti_Suzuki_WagonR_LXi11,
			Maruti_Suzuki_WagonR_LXi12,
		],
		category: "petrol",
		price: 99000,
		tags: "maruti suzuki wagonr dark blue solid",
		description: "",
		type: "Petrol",
		make_year: "2005",
		year: 2005,
		registeration_year: "Sep 2005",
		transmission: "Manual",
		no_of_owner: 2,
		km_driven: 44690,
	},

	{
		id: 18,
		title: "Mahindra KUV 100 K4 Plus",
		thumbnail: Mahindra_KUV_100_K4_Plus1,
		images: [
			Mahindra_KUV_100_K4_Plus1,
			Mahindra_KUV_100_K4_Plus2,
			Mahindra_KUV_100_K4_Plus3,
			Mahindra_KUV_100_K4_Plus4,
			Mahindra_KUV_100_K4_Plus5,
			Mahindra_KUV_100_K4_Plus6,
			Mahindra_KUV_100_K4_Plus7,
			Mahindra_KUV_100_K4_Plus8,
			Mahindra_KUV_100_K4_Plus9,
			Mahindra_KUV_100_K4_Plus10,
			Mahindra_KUV_100_K4_Plus11,
			Mahindra_KUV_100_K4_Plus12,
			Mahindra_KUV_100_K4_Plus13,
			Mahindra_KUV_100_K4_Plus14,
			Mahindra_KUV_100_K4_Plus15,
		],
		category: "petrol",
		price: 359000,
		tags: "maruti KUV100 dark gray solid",
		description: "",
		type: "Petrol",
		make_year: "2016",
		year: 2016,
		registeration_year: "Sep 2016",
		transmission: "Manual",
		no_of_owner: 1,
		km_driven: 11000,
	},

	{
		id: 19,
		title: "Honda Amaze SMT 1.2",
		thumbnail: Honda_Amaze_SMT1,
		images: [
			Honda_Amaze_SMT1,
			Honda_Amaze_SMT2,
			Honda_Amaze_SMT3,
			Honda_Amaze_SMT4,
			Honda_Amaze_SMT5,
			Honda_Amaze_SMT6,
			Honda_Amaze_SMT7,
			Honda_Amaze_SMT8,
			Honda_Amaze_SMT9,
			Honda_Amaze_SMT10,
			Honda_Amaze_SMT11,
			Honda_Amaze_SMT12,
			Honda_Amaze_SMT13,
		],
		category: "petrol",
		price: 309000,
		tags: "honda amaze smt dark red solid",
		description: "",
		type: "Petrol",
		make_year: "2014",
		year: 2014,
		registeration_year: "Sep 2014",
		transmission: "Manual",
		no_of_owner: 2,
		km_driven: 43000,
	},
];

export default productsData;
