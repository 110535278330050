import React, { useEffect, useState } from "react";
import { FaBars, FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.svg";
import ResMenu from "./ResMenu";
import SidebarSearch from "./SidebarSearch";

const Navbar = () => {
  const location = useLocation().pathname;
  const [navbar, setNavbar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showResMenu, setShowResMenu] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 1) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const openSidebar = () => {
    if (!showResMenu) setShowSidebar(true);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };
  const openResMenu = () => {
    if (!showSidebar) setShowResMenu(true);
  };
  const closeResMenu = () => {
    setShowResMenu(false);
  };
  showSidebar || showResMenu
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <React.Fragment>
      <NavbarComponent navbar={navbar} location={location}>
        <SidebarSearch
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />
        <ResMenu showSidebar={showResMenu} closeResMenu={closeResMenu} />
        <Link to="/">
          <img
            id="logo"
            style={
              (showSidebar || showResMenu) && window.innerWidth < 768
                ? { zIndex: "1", display: "none" }
                : { zIndex: "1" }
            }
            src={logo}
            alt="logo_pic"
          />
        </Link>

        <NavList showSidebar={showSidebar}>
          <NavItem id="contact-navbar">
            <span> Reach out to us : </span>+91-76050 82968 / +91-76050 58314
          </NavItem>
          {!showSidebar && (
            <Link className="nav__link" to="/">
              <NavItem navbar={navbar}>Home</NavItem>
            </Link>
          )}
          {!showSidebar && (
            <Link className="nav__link" to="/catalog">
              <NavItem navbar={navbar}>Catalog</NavItem>
            </Link>
          )}
          {!showSidebar && (
            <Link className="nav__link" to="/about">
              <NavItem navbar={navbar} style={{ width: 73 }}>
                About Us
              </NavItem>
            </Link>
          )}
          {/* <Link className="nav__link" to="/privacy-policy">
            <NavItem navbar={navbar}>Privacy Policy</NavItem>
          </Link> */}
          <NavItem navbar={navbar}>
            {showSidebar ? (
              <MdClose size={30} onClick={closeSidebar} />
            ) : (
              <FaSearch
                className="search-icon"
                size={25}
                onClick={openSidebar}
              />
            )}
          </NavItem>
        </NavList>

        <ResponsiveMenu
          showSidebar={showSidebar}
          // style={showSidebar ? { marginRight: "55%", zIndex: "10" } : {}}
        >
          <SearchItem navbar={navbar}>
            {showResMenu ? (
              <MdClose
                size={30}
                className="close-icon "
                onClick={closeResMenu}
              />
            ) : (
              <FaBars
                className="menu_bar"
                size={32}
                style={
                  showSidebar || showResMenu
                    ? { display: "none" }
                    : { marginTop: "2px" }
                }
                onClick={openResMenu}
              />
            )}
          </SearchItem>

          <SearchItem navbar={navbar}>
            {showSidebar ? (
              <MdClose
                className="close-icon "
                size={30}
                onClick={closeSidebar}
              />
            ) : (
              <FaSearch
                
                size={25}
                className="search_icon"
                style={(showSidebar || showResMenu) && { display: "none" }}
                onClick={openSidebar}
              />
            )}
          </SearchItem>
        </ResponsiveMenu>
      </NavbarComponent>
    </React.Fragment>
  );
};

const NavbarComponent = styled.div`
  height: 6rem;
  width: 100%;
  padding: 0 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all 0.3s;
  background-color: ${({ navbar, location }) =>
    navbar || location === "/catalog" ? "#373737" : "transparent"};
  color: ${({ navbar }) => (navbar ? "#f8be3e" : "#fff")};

  .search-icon {
    cursor: pointer;
  }
  .menu_bar {
    display: none;
    transition: all 0.3s;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    padding-right: 3rem;
    padding-left: 10rem;

    .menu_bar {
      display: unset;
      margin-top: -8px;
      size: 1120px;
    }
  }

  @media (max-width: 1200px) {
    padding-left: 3rem;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 80px;
  transition: all 0.3s;
  margin-right: ${({ showSidebar }) => (showSidebar ? "280px" : "0")};
  z-index: 1;

  @media (max-width: 1200px) {
    display: none;
  }

  .nav__link {
    text-decoration: none;
  }
  #contact-navbar {
    white-space: nowrap;
    margin-right: 50px;
    opacity: 0.9;
  }
  #contact-navbar:hover {
    color: inherit;
  }
`;

const NavItem = styled.li`
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ navbar }) => (navbar ? "#f8be3e" : "#fff")};

  &:hover {
    color: ${({ navbar }) => (navbar ? "#fff" : "#f8be3e")};
  }
`;

const SearchItem = styled.div``;

const ResponsiveMenu = styled.div`
  display: none;
  width: 120px;
  height: 40px;
  margin-left: auto;
  gap: 5px;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  transition: all 0.3s;
  @media (max-width: 1200px) {
    display: flex;
  }

  .search_icon {
    cursor: pointer;
  }
  .close-icon {
      position: absolute;
      right: 20px;
      top: 15px;
    }
    
  // margin-right: ${({ showSidebar }) => (showSidebar ? "55%" : "0")};
  // margin-top: ${({ showSidebar }) => (showSidebar ? "60px" : "0")};

  // @media (max-width: 550px) {
    // position: ${({ showSidebar }) =>
      showSidebar ? "absolute" : "relative"};
    // top: ${({ showSidebar }) => (showSidebar ? " -50px" : "0")};
    // left: ${({ showSidebar }) => (showSidebar ? "-30px" : "0")};
  }
`;

export default Navbar;
