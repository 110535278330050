import React from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";

const CheckBox = ({ checkboxData, Checked, setChecked, showLoading }) => {
	const handleToggle = (value) => {
		const currentIndex = Checked.indexOf(value);
		const newChecked = [...Checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
		showLoading();
		// console.log(newChecked);
	};

	return (
		<CheckboxContainer>
			{checkboxData.map((value, index) => (
				<label key={index}>
					<Checkbox
						sx={{
							color: "default",
							"&.Mui-checked": {
								color: "#f8be3e",
							},
						}}
						onChange={() => handleToggle(value)}
						checked={Checked.indexOf(value) === -1 ? false : true}
					/>
					<p className="value">{value.name}</p>
				</label>
			))}
		</CheckboxContainer>
	);
};

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: column;

	label {
		font-weight: 500;
		display: flex;
		align-items: center;
		.value {
			opacity: 0.8;
			font-size: 14px;
			cursor: pointer;
		}
	}
`;

export default CheckBox;
