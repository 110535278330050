import React from 'react';
import styled from 'styled-components';

const Button = ({ buttonName }) => {
    return (
        <ButtonComponent>
            {buttonName}
        </ButtonComponent>
    )
}

const ButtonComponent = styled.button`
    background-color: #f8be3e;
    height: 3rem;
    width: 10rem;
    color: #000;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all .3s;

    &:hover {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
    }
`;

export default Button
