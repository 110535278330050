import React from "react";
import Landing from "../components/Landing";
import OurServices from "../components/OurServices";

function Home() {
  return (
    <>
      {window.scrollTo({ top: 0, behavior: "smooth" })}
      <Landing />
      <OurServices />
    </>
  );
}

export default Home;
