import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
}
body{
    background-color:rgb(252,252,252);
}

    span {
        color: #f8be3e;
        font-weight: 700;
    }
`;

export default GlobalStyle;
