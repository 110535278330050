import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";

function Contact() {
	const [form, setForm] = useState({
		name: "",
		phone: "",
		email: "",
		message: "",
	});
	const [error, setError] = useState(false);

	function submitForm(e) {
		e.preventDefault();
		axios.defaults.headers.post["Content-Type"] = "application/json";
		axios
			.post("https://formsubmit.co/ajax/contact@universalwheels.in", form)
			.then((response) => {
				console.log(response.data.message);
				setForm({
					name: "",
					phone: "",
					email: "",
					message: "",
				});
			})
			.catch((error) => console.log(error));
	}
	return (
		<ContactUsContainer id="contact">
			<h2>
				Contact <span style={{ color: "#f8be3e" }}> Us</span>
			</h2>
			<div>
				<form onSubmit={submitForm}>
					<div>
						<label name="name">
							Name <br />
							<span className="material-icons">person</span>
							<input
								name="name"
								type="text"
								value={form.name}
								required
								onChange={(e) => setForm({ ...form, name: e.target.value })}
							/>
						</label>
						<label name="phone">
							Phone
							<br />
							<span className="material-icons">phone_in_talk</span>
							<input
								name="phone"
								type="tel"
								value={form.phone}
								required
								onChange={(e) => setForm({ ...form, phone: e.target.value })}
							/>
						</label>
						<label name="email">
							Email Address
							<br />
							<span className="material-icons">email</span>
							<input
								name="email"
								type="email"
								value={form.email}
								required
								onChange={(e) => setForm({ ...form, email: e.target.value })}
							/>
						</label>
					</div>
					<label name="message" id="message">
						<span className="material-icons chat">chat</span>
						<textarea
							name="message"
							value={form.message}
							required
							onChange={(e) => setForm({ ...form, message: e.target.value })}
						/>
						<button id="send__button">Send</button>
					</label>
				</form>
				<p id="error-msg" style={{ visibility: error ? "visible" : "hidden" }}>
					Please fill all the fields.
				</p>
			</div>
			<Footer>
				{/* <div className="footer__top">
          <div className="triangle-up"></div>

          <div className="box">
            <div>
              <h4>Home</h4>
              <p>Lorem ipsum</p>
            </div>
            <div>
              <h4>Catalog</h4>
              <p>Lorem ipsum</p>
            </div>
            <div>
              <h4>About Us</h4>
              <p>Lorem ipsum</p>
            </div>
          </div>
        </div> */}
				<div className="footer__bottom">
					<div className="know__more">
						<p>Know more</p>
						<span className="material-icons">send</span>
					</div>
					<div className="social__icons">
						<div>
							<a
								href="https://www.instagram.com/universal.wheels/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-instagram"></i>
							</a>
						</div>
						<div>
							<a
								href="https://www.youtube.com/channel/UCkdMxVywA_plOXT1txxP-oQ"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-youtube"></i>
							</a>
						</div>
						{/* <div>
							<i className="fab fa-facebook-f"></i>
						</div> */}
					</div>
				</div>
			</Footer>
		</ContactUsContainer>
	);
}

const ContactUsContainer = styled.div`
	background: #2a2a2a;
	padding: 40px;
	color: #fff;
	z-index: 1;
	position: relative;
	margin-top: -7px;

	h2 {
		margin-left: 100px;
		font-weight: 300;
		@media (max-width: 768px) {
			margin-left: 0;
		}
	}
	form {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		gap: 70px;
		margin-top: 30px;
		div {
			display: flex;
			flex-direction: column;
			gap: 20px;
			input {
				padding: 12px 17px 8px 40px;
				border-radius: 2px;
				border: none;
				outline: none;
				background: #3d3d3d;
				color: #fff;
				width: 250px;
				font-family: "Poppins", sans-serif;

				@media (max-width: 1030px) {
					width: 100%;
				}
			}
		}

		textarea {
			height: 210px;
			width: 700px;
			padding: 10px 0;
			padding-left: 40px;
			background: #3d3d3d;
			border-radius: 2px;
			border: none;
			outline: none;
			color: #fff;
			resize: none;
			font-family: "Poppins", sans-serif;

			@media (max-width: 1030px) {
				margin-top: 40px;
				width: 100%;
			}
		}

		span {
			position: absolute;
			margin: 10px 0 0 7px;
			font-size: 20px;
			color: #fff;
		}

		#message {
			position: relative;

			.chat {
				@media (max-width: 1030px) {
					margin-top: 50px;
				}
			}

			#send__button {
				outline: none;
				background: transparent;
				border: 0.124rem solid #f8be3e;
				color: #f8be3e;
				position: absolute;
				right: 3%;
				top: 65%;
				border-radius: 2px;
				padding: 10px 40px;
				font-family: Poppins, sans-serif;
				cursor: pointer;
				transition: 0.3s ease-in-out;
				font-size: 15px;
				@media (max-width: 1030px) {
					font-size: 12px;
					padding: 8px 30px;
					top: -140%;
				}
			}
			#send__button:hover {
				background: #f8be3e;
				color: #fff;
			}
		}

		@media (max-width: 1030px) {
			display: block;
		}
	}
	#error-msg {
		text-align: center;
		margin-top: 10px;
		color: red;
		visibility: hidden;
	}
`;

const Footer = styled.div`
	margin-top: 50px;
	padding-bottom: 30px;
	.footer__top {
		display: flex;
		justify-content: center;
		color: #000;

		.triangle-up {
			width: 0;
			height: 0;
			border-left: 40px solid transparent;
			border-bottom: 50px solid #ab7f05;
		}
		.box {
			display: flex;
			gap: 60px;
			background: #f5b70c;
			padding: 30px 90px;

			p {
				font-weight: 400;
				opacity: 0.8;
			}
			@media (max-width: 510px) {
				flex-direction: column;
				padding: 30px 40px;
				gap: 20px;
				width: 100%;
			}
		}
	}

	.footer__bottom {
		margin-top: 60px;
		display: flex;
		justify-content: center;
		position: relative;

		.know__more {
			display: flex;
			gap: 120px;
			border: 2px solid #6a6a6a;
			color: #6a6a6a;
			padding: 10px 30px;
			@media (max-width: 1030px) {
				padding: 10px;
				min-width: 200px;
				max-width: 300px;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}
		.know__more span {
			display: inline-block;
			cursor: pointer;
			transition: 0.3s;
		}
		.know__more span:hover {
			transform: scale(1.2);
		}

		.social__icons {
			position: absolute;
			right: 120px;
			display: flex;
			gap: 20px;
			div {
				transition: all 0.3s;
				a {
					color: #f5b70c;
				}
			}
			div:hover {
				color: white;
				transform: scale(1.1);
				a {
					color: white;
					transform: scale(1.1);
				}
			}

			@media (max-width: 1030px) {
				top: 70px;
				left: calc(100% - 55vw);
			}

			@media (max-width: 340px) {
				top: 90px;
				left: calc(100% - 60vw);
			}

			div {
				padding: 7px 12px;
				background: #5a5a5a;
				color: #f5b70c;
				cursor: pointer;
			}
		}
	}
`;

export default Contact;
