import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function ItemCard({ productId, productImage, productName, year, price }) {
	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	const navigate = useNavigate();

	return (
		<>
			<Card>
				<img
					alt=""
					src={productImage}
					className="item_image"
					onClick={() => navigate(`/catalog/${productId}`)}
				/>
				<div className="item__details">
					<div
						style={{ cursor: "pointer" }}
						onClick={() => navigate(`/catalog/${productId}`)}
					>
						<p id="brand">{productName}</p>
						<p>
							<span
								style={{
									fontFamily: "sans-sarif",
									color: "black",
									fontWeight: "normal",
									fontSize: "15px",
								}}
							>
								₹{" "}
							</span>
							{numberWithCommas(price)}
						</p>
					</div>
					<p id="year">{year}</p>
					<a href="#contact" className="buy">
						Contact Us
					</a>
				</div>
			</Card>
		</>
	);
}

const Card = styled.div`
	width: 410px;
	height: 352px;
	display: flex;
	flex-direction: column;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
	transition: all 0.3s;

	margin: 0 10px 20px;
	#year {
		text-align: center;
	}
	@media (max-width: 1200px) {
		width: 350px;
		height: 313px;
	}

	@media (max-width: 350px) {
		width: 300px;
		height: 280px;
	}
	#brand {
		font-weight: bold;
		opacity: 0.8;
	}
	.item_image {
		background-color: #eaeaea;
		height: 257px;
		object-fit: contain;
		cursor: pointer;
	}

	.item__details {
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: all 0.3s;
		background: #fcfcfc;
		box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 15px;

		div {
			flex: 0.5;

			padding: 0 0 0 15px;
		}

		p {
			flex: 0.25;
		}
		.buy {
			flex: 0.4 !important;
			transition: all 0.3s;
			text-align: center;
			padding: 27px 0 27px 0;
			font-size: 16px;
			color: black;
			background-color: #eaeaea;
			text-decoration: none;
		}
	}

	img {
		max-width: 100%;

		flex: 0.9;
	}

	&:hover {
		.item__details {
			background-color: #2a2a2a;
			color: #fff;
		}

		.buy {
			background-color: #ffbf1e;
			color: #2a2a2a;
		}
	}
`;

export default ItemCard;
