import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GlobalStyle from "./GlobalStyle";

const ServiceCard = ({ header, Logo, text }) => {
	const [color, setColor] = React.useState("black");

	return (
		<React.Fragment>
			<GlobalStyle />
			<ServiceCardContainer
				onMouseEnter={() => setColor("white")}
				onMouseLeave={() => setColor("black")}
			>
				<p className="logo">
					<Logo fill={color} />
				</p>
				<Heading>{header}</Heading>
				<Content>{text}</Content>
				{/* <HashLink smooth to={"/#contact"}> */}
				<Link to="/catalog">
					<Chat>Let's Chat</Chat>
				</Link>
				{/* </HashLink> */}
			</ServiceCardContainer>
		</React.Fragment>
	);
};

const Heading = styled.p`
	margin: 30px 0 20px 0;
	font-size: 20px;
`;
const Content = styled.p`
	width: 80%;
	text-align: center;
	opacity: 0.9;
	font-size: 15px;
`;
const Chat = styled.p`
	margin-top: 20px;
	text-decoration: underline;
	font-size: 18px;
	color: #000;

	&:hover {
		cursor: pointer;
	}
`;

const ServiceCardContainer = styled.div`
	min-width: 230px;
	width: 250px;
	height: 320px;
	border: 1px solid #cacaca;
	margin: 20px 40px 0;
	display: flex;
	flex-direction: column;
	background: #fcfcfc;

	align-items: center;
	transition: all 0.4s;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	.logo {
		margin-top: 40px;
		max-width: 60px;
	}
	&:hover {
		background-color: #2a2a2a;
		color: white;

		${Heading} {
			color: #fbc500;
		}
		${Chat} {
			color: #fbc500;
		}
	}

	@media (max-width: 1440px) {
		margin: 20px 20px 0;
	}
`;

export default ServiceCard;
