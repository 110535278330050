import React from "react";

function CustomSellLogo({ fill }) {
	return (
		<svg
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill={"none"}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 17.5H14.5L19.5 32H31.5L37 17.5"
				stroke={fill}
				strokeWidth="3"
				strokeLinecap="round"
			/>
			<circle cx="25" cy="25" r="23.5" stroke={fill} strokeWidth="3" />
			<circle cx="21" cy="37" r="2" fill={fill} />
			<circle cx="30" cy="37" r="2" fill={fill} />
			<path
				d="M21 16L25 22.5M25 22.5L29 16M25 22.5V29"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<line x1="19" y1="21" x2="31" y2="21" stroke={fill} strokeWidth="2" />
			<line x1="19" y1="24" x2="31" y2="24" stroke={fill} strokeWidth="2" />
			<path
				d="M27 11.0001L30 9.00007C30.8333 8.50007 32.7 7.60007 33.5 8.00007C34.3 8.40007 35.5 9.50002 36 10"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<path
				d="M26 10V12H28"
				stroke={fill}
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
}

export default CustomSellLogo;
