import React from "react";
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FilterDropdown({ title, content, expand }) {
  return (
    <>
      <Accordion
        sx={{
          width: "95%",
          background: "#f7f7f7",
          "&:before": {
            display: "none",
          },
        }}
        disableGutters={true}
        expanded={expand}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography
            sx={{
              padding: "5px 20px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginLeft: "6px" }}>
          {content}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default FilterDropdown;
