import React from "react";
import styled from "styled-components";
import AboutBackground from "../assets/images/about-background.png";
import whyImage from "../assets/images/Group 784 (1).png";
import pathImage from "../assets/images/Path 1777.png";

function About() {
	return (
		<>
			{window.scrollTo({ top: 0, behavior: "smooth" })}

			<HeroSection>
				<h1>
					<span style={{ color: "#f8be3e" }}>About</span> Us
				</h1>
				<p className="about-content">
					Universal Wheels,{" "}
					<span style={{ fontWeight: "normal", opacity: "1" }}>
						Mahindra First Choice
					</span>{" "}
					is a certified pre-owned multi brand car dealership in Kolkata.
					Universal Wheels is a one stop shop for your used car requirements
					i.e., Buy, Sell or Exchange.
					<br />
					Wide range of cars across varied categories can be found under one
					roof at Universal Wheels. All our cars undergo a stringent 140 check
					points to ensure our potential customers get quality certified cars.
				</p>
			</HeroSection>
			<WhyUniversalWheels>
				<h1>Why Universal Wheels?</h1>
				<div>
					<img
						alt=""
						src={whyImage}
						className="whyUWImage"
						width="300"
						height="350"
					/>
					<img
						alt=""
						src={pathImage}
						className="pathImage"
						width="300"
						height="350"
					/>
					<Content>
						<p>
							At Universal Wheels, we offer{" "}
							<span
								style={{
									fontWeight: "normal",
									opacity: "1",
								}}
							>
								unique personalized experience
							</span>{" "}
							as every customer behind the wheels has different expectations.
							Additionally, we offer certified pre-owned cars and not just
							pre-owned cars. All facts about the cars are laid bare to the
							customer and instilling trust factor for a life long relationship.
							Quality is of paramount importance to us. Our quality car and
							service offering keeps getting us more and more customers.
						</p>
						<p>
							Crew members at Universal Wheels are passionate about cars and
							want to extend their knowledge to all through this platform.
						</p>
						<p>
							Certified pre-owned cars, finance options, insurance, RTO related
							paperwork – we make it all hassle free for you. And Yes, we
							Buyback the car from you. Isnt’t it great buying a certified
							pre-owned car with warranty benefits along with a confirmed future
							value of your car?
						</p>
					</Content>
				</div>
			</WhyUniversalWheels>
			{/* <PrivacyPolicy>
        <div className="privacy-innercontainer">
          <div className="content__and__image">
            <div>
              <h3>Privacy Policy</h3>
              <br />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                duis nam at pharetra dolor fringilla est. Non arcu dictum risus
                risus aliquam sem dictum a. Sed mattis vitae turpis enim. Dui
                habitant massa viverra felis, mi justo maecenas sed varius. Ac
                volutpat orci, sed lorem eleifend velit in tortor mauris. Orci,
                neque arcu at cursus senectus neque augue.
              </p>
              <p className="learn-more">
                Learn more about privacy policy
                <br />
                <p className="learn-more-line"></p>
              </p>
            </div>
            <div>
              <img
                alt=""
                src={privacyImage}
                width="300"
                height="300"
                style={{ marginLeft: "15px" }}
              />
            </div>
          </div>
          <div className="privacy__policy__buttons">
            <button id="cancel">Cancel</button>
            <button id="confirm">Confirm</button>
          </div>
        </div>
      </PrivacyPolicy> */}
		</>
	);
}

const HeroSection = styled.div`
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url(${AboutBackground});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 70vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	padding-bottom: 20px;
	h1 {
		font-size: 70px;
		margin-bottom: 40px;
		opacity: 1;
	}
	p {
		width: 60%;
		text-align: center;
		font-size: 18px;
		opacity: 0.9;
		@media (max-width: 1000px) {
			width: 80%;
		}
	}
	@media (max-width: 425px) {
		height: 90vh;
	}
`;

// const AboutContent = styled.div`
//   background: #eee;

//   h3 {
//     font-family: "Poppins", sans-serif;
//   }

//   .about__us,
//   .our__mission,
//   .our__vision {
//     display: flex;
//     justify-content: space-between;
//     padding: 40px 50px;
//     @media (max-width: 768px) {
//       display: block;
//       padding: 0 25px;
//       text-align: center;
//       margin: 50px 0;
//     }

//     div:nth-child(2) {
//       flex: 0.9;

//       @media (max-width: 768px) {
//         text-align: left;
//         margin-top: 20px;
//       }
//     }
//   }

//   .our__mission {
//     background: #fff;
//   }

//   p {
//     color: #949494;
//     font-family: "Segoe UI", sans-serif;
//     font-size: 17px;
//   }
// `;

const WhyUniversalWheels = styled.div`
	width: 60%;
	margin: 150px auto;

	.whyUWImage,
	.pathImage {
		height: 550px;
		width: 450px;
	}
	@media only screen and (max-width: 1450px) {
		width: 80%;
	}
	@media only screen and (max-width: 1450px) {
		width: 100%;
	}

	h1 {
		/*   background: #2a2a2a;*/
		/*color: #fff;*/
		color: #f8be3e;
		padding: 40px;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 28px;
		}
	}

	.whyUWImage {
		@media (max-width: 1100px) {
			height: 450px;
			width: 350px;
		}
		@media (max-width: 1000px) {
			height: 600px;
			width: 500px;
		}

		@media (max-width: 768px) {
			width: 400px;
			height: 400px;
		}
		@media (max-width: 425px) {
			width: 280px;
			height: 280px;
		}
	}

	.pathImage {
		position: absolute;
		top: 60px;
		left: 32px;
		@media (max-width: 1100px) {
			height: 450px;
			width: 350px;
		}
		@media (max-width: 1000px) {
			display: none;
		}
	}
	div {
		display: flex;
		position: relative;
		justify-content: space-between;
		padding: 40px 50px;

		@media (max-width: 1000px) {
			dislpay: flex;
			flex-direction: column;
			align-items: center;
		}

		@media (max-width: 768px) {
			display: block;
			padding: 0 25px;
			text-align: center;
			margin: 50px 0;
		}

		p {
			flex: 0.9;
			font-family: "Segoe UI", sans-serif;
			font-size: 19px;

			@media (max-width: 1000px) {
				font-size: 17px !important;
				margin-top: 30px;
			}
			@media (max-width: 768px) {
				text-align: left;
				margin-top: 20px;
			}
		}
	}
`;

const PrivacyPolicy = styled.div`
	background: #3d3d3d;
	padding: 30px 0;

	@media only screen and (max-width: 768px) {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.privacy-innercontainer {
		width: 80%;
		margin: auto;
	}

	h3 {
		font-size: 35px;
		letter-spacing: 1px;
		opacity: 0.9;
	}

	p {
		opacity: 0.9;
	}
	.content__and__image {
		display: flex;
		justify-content: space-between;
		padding: 10px 50px 20px;
		color: #fff;
		margin: auto;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
	}

	div:nth-child(1) {
		flex: 0.6;

		@media (max-width: 768px) {
			order: 2;
			margin: 20px 0;
			padding: 0 15px;
			flex: 0.8;
		}
		@media (max-width: 425px) {
			flex: 1;
		}
	}
	.privacy__policy__buttons {
		display: flex;
		gap: 20px;
		justify-content: flex-end;

		@media only screen and (max-width: 768px) {
			justify-content: center;
		}
	}
	.learn-more {
		margin-top: 50px;
		opacity: 1;
		cursor: pointer;
	}
	.learn-more-line {
		height: 2px;
		background-color: white;
		width: 200px;
		margin-top: 5px;
		@media only screen and (max-width: 768px) {
			width: 80%;
			margin: 5px auto;
		}
	}
	button {
		padding: 10px 30px;
		outline: none;
		border: none;
		font-family: "Poppins", sans-serif;
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:nth-child(1) {
			background-color: transparent;
			border: 1px solid #fff;
			color: #fff;
		}

		&:nth-child(2) {
			background-color: #f8be3e;
			color: #000;
		}
	}

	#confirm:hover,
	#cancel:hover {
		transform: scale(1.1);
	}
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto auto auto 20px;
	p {
		margin-bottom: 20px !important;
		font-size: 15px !important;
		opacity: 0.9;
	}
`;
export default About;
