import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SidebarMenuResponsive = ({ showSidebar, closeResMenu }) => {
  return (
    <SidebarComponent showSidebar={showSidebar}>
      <ListItemContainer>
        <Link className="nav__link" to="/" onClick={() => closeResMenu()}>
          <ListItem>Home</ListItem>
        </Link>
        <Link
          className="nav__link"
          to="/catalog"
          onClick={() => closeResMenu()}
        >
          <ListItem>Catalog</ListItem>
        </Link>
        <Link className="nav__link" to="/about" onClick={() => closeResMenu()}>
          <ListItem>About Us</ListItem>
        </Link>
      </ListItemContainer>
    </SidebarComponent>
  );
};

const SidebarComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 0;
  width: ${({ showSidebar }) => (showSidebar ? "450px" : "0")};
  visibility: ${({ showSidebar }) => (showSidebar ? "visible" : "hidden")};
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #000;
  overflow-x: hidden;
  transition: all 0.3s;

  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 550px) {
    width: 60%;
  }
  @media (max-width: 400px) {
    width: 70%;
  }
  .nav__link {
    text-decoration: none !important;
  }
`;

const ListItemContainer = styled.ul`
  padding: 100px 20px;
`;
const ListItem = styled.li`
  list-style: none;
  color: white;
  margin: 50px 0;
  font-size: 20px;
`;
export default SidebarMenuResponsive;
