import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.esm";
import img1 from "../assets/images/baleno/img1.JPG";
import img10 from "../assets/images/baleno/img10.JPG";
import img11 from "../assets/images/baleno/img11.JPG";
import img12 from "../assets/images/baleno/img12.JPG";
import img2 from "../assets/images/baleno/img2.JPG";
import img3 from "../assets/images/baleno/img3.JPG";
import img4 from "../assets/images/baleno/img4.JPG";
import img5 from "../assets/images/baleno/img5.JPG";
import img6 from "../assets/images/baleno/img6.JPG";
import img7 from "../assets/images/baleno/img7.JPG";
import img8 from "../assets/images/baleno/img8.JPG";
import img9 from "../assets/images/baleno/img9.JPG";
import Modal from "../components/Modal";
import Table from "../components/Table";
import ProductContext from "../ProductContext";

function CatalogItem() {
	const { products } = useContext(ProductContext);
	const { productId } = useParams();
	var images = [
		img1,
		img2,
		img3,
		img4,
		img5,
		img6,
		img7,
		img8,
		img9,
		img10,
		img11,
		img12,
	];
	// eslint-disable-next-line eqeqeq
	const thisProduct = products.find((prod) => prod.id == productId);

	const [currImg, setCurrImg] = useState();
	const [showReadMore, setShowReadMore] = useState(true);
	const [showDescription, setShowDescription] = useState(false);
	const [first, setFirst] = useState(true);
	const swiperRef = React.useRef(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (first) {
			if (thisProduct) setCurrImg(thisProduct.images[0]);
			// setCurrImg(images[0]);
			setFirst(false);
		}
	});
	function readMore() {
		if (showReadMore) {
			setShowReadMore(false);
			setShowDescription(true);
		} else {
			setShowReadMore(true);
			setShowDescription(false);
		}
	}
	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	var breakpoints = {
		700: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		420: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		0: {
			slidesPerView: 1,
			spaceBetween: 10,
		},
	};

	function changeImg(e) {
		setCurrImg(thisProduct.images[e.realIndex]);
		// setCurrImg(images[e.realIndex]);
	}

	return (
		<>
			{window.scrollTo({ top: 0, behavior: "smooth" })}
			<div>
				{showModal && <Modal img={currImg} setShowModal={setShowModal} />}
				{thisProduct == null ? (
					<div>Loading...</div>
				) : (
					<CatalogItemContainer>
						<div className="line-over"></div>
						<div className="innercontainer">
							<Link to="/catalog" className="back">
								<span>
									<IoIosArrowBack />
								</span>{" "}
								Back to Catalog
							</Link>
							<div className="image-container">
								<div className="image-innercontainer">
									<img
										id="preview-img"
										alt=""
										src={currImg}
										onClick={() => {
											setShowModal(true);
										}}
									/>
								</div>
								<div className="image-swiper">
									<p
										className="left-arrow"
										onClick={() => {
											swiperRef.current.swiper.slidePrev();
										}}
									>
										<IoIosArrowBack />
									</p>

									<Swiper
										slidesPerView={3}
										ref={swiperRef}
										// spaceBetween={20}
										breakpoints={breakpoints}
										slideToClickedSlide={true}
										updateOnWindowResize={true}
										// centeredSlides={true}
										loop={true}
										onSlideChange={changeImg}
										// onSwiper={(swiper) => {console.log(swiper)}}
									>
										{thisProduct.images.map((img, index) => {
											// {images.map((img, index) => {
											return (
												<SwiperSlide key={index}>
													<img alt="" src={img} />
												</SwiperSlide>
											);
										})}
									</Swiper>
									<p
										className="right-arrow"
										onClick={() => {
											swiperRef.current.swiper.slideNext();
										}}
									>
										<IoIosArrowForward />
									</p>
								</div>
							</div>
							<div className="item__details">
								<p className="title">{thisProduct.title}</p>
								<p>
									<b style={{ fontSize: "35px", marginRight: "20px" }}>
										{thisProduct.year}
									</b>
									Model
								</p>
								<br />
								{thisProduct.description && (
									<>
										<p
											style={
												showDescription
													? { display: "block" }
													: { display: "-webkit-box" }
											}
											className="description"
										>
											{thisProduct.description}
										</p>
										{showReadMore ? (
											<p onClick={readMore} className="read-more">
												Read more
											</p>
										) : (
											<p onClick={readMore} className="read-more">
												Hide
											</p>
										)}
									</>
								)}
								<br />
								<div className="price__and__button">
									{/* <b style={{ color: "#fff" }}>{thisProduct.price}</b> */}
									<p style={{ fontSize: "23px", fontWeight: "bold" }}>
										<span>₹ </span>
										{numberWithCommas(thisProduct.price)}
									</p>
									<a href="#contact">
										<button className="buy__now">
											Contact Now
											<span
												className="material-icons"
												style={{
													verticalAlign: "top",
													fontSize: "20px",
													marginLeft: "10px",
												}}
											>
												shopping_bag
											</span>
										</button>
									</a>
								</div>
							</div>
						</div>
						<Table data={thisProduct} />
					</CatalogItemContainer>
				)}
			</div>
		</>
	);
}
const CatalogItemContainer = styled.div`
  .line-over {
    position: absolute;
    top: 0;
    width: 150px;
    background-color: #37373766;
    height: 200vh;
    z-index: 0;
    left: 20%;
    @media (max-width: 1084px) {
      height: 1500px;
    }

    @media (max-width: 1000px) {
      left: 23%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  background: #151515;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  padding: 220px 100px;
  @media (max-width: 768px) {
    display: flex;
  }
  .innercontainer {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1100px) {
      // display: contents !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;
    }
  }

  .back {
    position: absolute;
    top: 170px;
    left: 200px;
    color: #f8be3e;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none;
    span {
      color: black;
      background-color: #f8be3e;
      display: flex;
      padding: 2px;
      margin-right: 5px;
    }
    @media (max-width: 1000px) {
      left: 15%;
    }
  }

  .back:hover {
    transform: scale(1.05);
  }
  
.image-container{
  width:600px;
  height:400px;
  margin-left: 100px;
  background-color: #1f1f1f;
  z-index:5;
  margin-bottom:100px;
  @media (max-width: 1100px) {
    margin: 0;
    margin-top: 150px;
    min-width: 0;
    width: 80%;
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }

}
  .image-innercontainer {
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    #preview-img {
      max-height: 100%;
      max-width: 100%;
      cursor: pointer;
    }
  }
  .left-arrow {
    color: #f8be3e;
    font-size: 40px;
    font-weight: normal;
    cursor: pointer;
    margin: auto 20px auto 0;
    @media (max-width: 500px) {
      margin: auto 0 auto 0;
    }
  }
  .right-arrow {
    color: #f8be3e;
    font-size: 40px;
    font-weight: normal;
    cursor: pointer;
    margin: auto 0 auto 20px;
    @media (max-width: 500px) {
      margin: auto 0 auto 0px;
    }
  }
  .image-swiper {
    max-width: 100%;
    margin: auto;
    display: flex;
    padding: 10px 0;
    background-color: #1f1f1f;
    justify-content: center;

    img {
      cursor: pointer;
      // max-width: 140px !important;
      max-height: 80px !important;
    }
    img:hover {
      trasform: scale(1.1);
    }
  }
  .swiper-wrapper {
    // justify-content: center;
    width: 600px;
  }
  .swiper-slide {
    opacity: 0.7;
    text-align: center;
  }
  .swiper-slide-active {
    opacity: 1;
    img {
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    font-weight: bolder;
    color: #f8be3e;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 25px;
  }
  .read-more {
    margin-top: 2px;
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.3s;
    color: #f8be3e;
    &:hover {
      text-decoration: underline;
    }
  }
  .description {
    opacity: 0.8;
    width: 75%;
    fontsize: 15px;
    fontweight: 400;
    overflow: hidden;
    transition: all 0.3s;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 1100px) {
      text-align: center;
      margin-bottom: 20px;
    }
    @media (max-width: 1200px) {
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    padding: 90px 0;
    // height: fit-content;
  }

  .item__details {
    flex: 0.7;
    color: #fff;
    margin-top:100px;

    @media (max-width: 1200px) {
      flex: 0.8;
    }
    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 150px;
      .title{
        text-align: center;
      }
    }
    .price__and__button {
      @media (max-width: 1100px) {
        display: flex;
        align-items: center;
        gap: 60px;
      }
    }
    p:nth-child(1) {
      font-size: 50px;
      opacity: 0.6;

      @media (max-width: 1100px) {
        font-size: 30px;
    
        }
      }
    }

    .buy__now {
      outline: none;
      background: transparent;
      border: 0.124rem solid #f8be3e;
      color: #f8be3e;
      border-radius: 1px;
      padding: 12px 50px;
      margin-top: 30px;
      font-family: Poppins, sans-serif;
      transition: all 0.3s;
      cursor: pointer;

      @media (max-width: 1100px) {
        padding: 5px;
        margin-top: 0;
      }
    }
    .buy__now:hover {
      span {
        color: #fff;
      }
      background: #f8be3e;
      color: #fff;
    }
  }
`;
export default CatalogItem;
