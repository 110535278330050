import React from "react";
import styled from "styled-components";
import Button from "../assets/Button";
import landingImage from "../assets/images/landing-bg.png";
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <LandingContainer>
      <LandingTagname>
        <p>TRAVEL</p>
        <h1>THE WORLD</h1>
        <p>IN THE</p>
        <p>COMFORT OF</p>
        <p>
          YOUR <span>DREAM CAR</span>
        </p>
      </LandingTagname>
      <ButtonsComponent>
        <Link to="/catalog">
          <Button buttonName="View Catalog" />
        </Link>
        <TagButton
          onClick={() =>
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            })
          }
        >
          <p>Contact us</p>
        </TagButton>
      </ButtonsComponent>
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10rem;
  padding-top: 3rem;
  height: 100vh;
  color: #fff;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${landingImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1084px) {
    text-align: center;
    align-items: center;
    width: 100%;
  }

  @media (min-width: 360px) and (max-width: 962px) {
    padding: 0 4rem;
  }

  @media (min-width: 360px) and (max-width: 702px) {
    padding: 0 1rem;
  }
`;

const LandingTagname = styled.div`
  margin-bottom: 2rem;
  line-height: 1.1;

  h1 {
    font-size: 3.5rem;
  }
  p {
    font-size: 3rem;
    font-weight: 100;
  }

  span {
    color: #f8be3e;
    font-weight: 700;
  }

  @media (max-width: 962px) {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 4.5rem;
    }

    span {
      font-size: 4.5rem;
    }
  }

  @media (min-width: 360px) and (max-width: 962px) {
    padding-top: 1.5rem;
  }

  @media (min-width: 360px) and (max-width: 702px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 3rem;
    }

    span {
      font-size: 3rem;
    }
  }
`;

const TagButton = styled.div`
  cursor: pointer;

  p {
    border-bottom: 1px solid #fff;
    padding-bottom: 0.5rem;
  }
`;

const ButtonsComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18rem;

  @media only screen and (max-width: 434px) {
    width: 20rem;
    flex-direction: column;
    gap: 16px;
  }
`;

export default Landing;
