import React from "react";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";

function Modal({ img, setShowModal }) {
  return (
    <ModalContainer>
      <p
        className="close"
        onClick={() => {
          setShowModal(false);
        }}
      >
        <IoClose />
      </p>
      <img src={img} alt="" />
    </ModalContainer>
  );
}
export default Modal;

const ModalContainer = styled.div`
  width: 70vw;
  height: 90vh;
  position: fixed;
  margin: auto;
  z-index: 999;
  background-color: #1f1f1f;
  left: 0;
  right: 0;
  top: 50%;
  display: flex;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  img {
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
    max-width: 100%;
    width: auto;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 30px;
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
  @media (max-width: 500px) {
    width: 90vw;
    height: 90vh;
  }
`;
