import React from "react";
import styled from "styled-components";

function ItemCard() {
  return (
    <>
      <Card>
        <div className="img skeleton"></div>
        <div className="item__details">
          <div>
            <p id="brand" className="skeleton"></p>
            <p className="skeleton price"></p>
          </div>
          <p className="skeleton" id="year"></p>
          <p className="buy skeleton"></p>
        </div>
      </Card>
    </>
  );
}

const Card = styled.div`
  background: #fcfcfc;
  width: 410px;
  height: 352px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 15px;
  margin: 0 10px 20px;

  .img.skeleton {
    height: 332px;
    margin: 10px;
    border-radius: 5px;
  }
  .skeleton {
    background-color: #eeeeeede;
    margin: 0 5px;
  }

  #year {
    height: 30px;
    width: 90%;
  }
  @media (max-width: 1300px) {
    width: 350px;
    height: 313px;
  }

  @media (max-width: 350px) {
    width: 300px;
    height: 280px;
  }
  #brand,
  .price {
    height: 20px;
    width: 90%;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .item__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;

    div {
      flex: 0.5;
    }

    p {
      flex: 0.25;
    }
    .buy {
      height: 50px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  img {
    width: 100%;
    flex: 0.9;
  }
`;

export default ItemCard;
