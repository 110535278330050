import React from "react";
import styled from "styled-components";
import BuyBack from "../assets/images/CustomBuyBackLogo";
import BuyLogo from "../assets/images/CustomBuyLogo";
import ExchangeLogo from "../assets/images/CustomExchangeLogo.js";
//SVGs
// import buyLogo from "../assets/images/buylogo.svg";
// import sellLogo from "../assets/images/sell-a-car.svg";
import SellLogo from "../assets/images/CustomSellLogo";
import bgImage from "../assets/images/ServicesBackground.png";
import GlobalStyle from "./GlobalStyle";
// import ServiceCard from "./ServiceCard";
import ServiceCard from "./ServiceCardNew";
const OurServices = () => {
	return (
		<React.Fragment>
			<GlobalStyle />
			<ServicesContainer>
				<ServicesHeader>
					<h3>Our</h3>
					<h1>Services</h1>
				</ServicesHeader>

				<ServicesSection>
					<ServiceCard
						header="Buy"
						Logo={BuyLogo}
						text={"Choose from variety of cars"}
					/>
					<ServiceCard
						header="Sell"
						Logo={SellLogo}
						text={"Call us to schedule valuation for your car"}
					/>
					<ServiceCard
						header="Exchange"
						Logo={ExchangeLogo}
						text={"Exhange your current car with a certified pre-owned car"}
					/>
					<ServiceCard
						header="Finance and Insurance"
						Logo={BuyBack}
						text={"Attractive finance and insurance offers available"}
					/>
				</ServicesSection>
			</ServicesContainer>
		</React.Fragment>
	);
};

const ServicesContainer = styled.div`
	background: url(${bgImage});
	background-repeat: no-repeat;
	background-position: initial;
	background-size: cover;
	height: 70vh;
	width: 100%;
	margin: 0 auto 150px auto;
	padding: 180px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	h3 {
		font-size: 28px;
		font-weight: lighter;
	}

	h1 {
		font-size: 40px;
		font-weight: bold;
	}
	@media only screen and (max-width: 1440px) {
		width: 100%;
	}
	@media only screen and (max-width: 1600px) {
		padding: 50px 0;
		margin: 0 auto 330px auto;
		text-align: center;
		background-position: top;
	}
	@media only screen and (max-width: 902px) {
		padding: 20px 0;
		text-align: center;
	}

	@media only screen and (max-width: 810px) {
		padding: 60px;
		align-items: center;
		height: 1500px;
	}

	@media only screen and (max-width: 902px) {
		padding: 60px;
	}

	@media only screen and (max-width: 375px) {
		margin: 0 auto 100px auto;
	}
`;

const ServicesSection = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@media only screen and (max-width: 810px) {
		flex-direction: column;
		justify-content: space-around;
		height: 312rem;
	}

	@media only screen and (max-width: 462px) {
		height: 412rem;
		justify-content: space-around;
	}
`;

const ServicesHeader = styled.div``;

export default OurServices;
